<template>
  <section class="tabs-container u-bg-color-white-base">
    <GenericTabsComponent
      :tabs-list="tabsList"
      @handleTabsClick="handleTabsClick"
    />
    <div class="u-spacing-mt-l selected-component">
      <component
        :is="fetchComponent(currentTab)"
        :key="key"
        class="selected-component"
        :entity-config="entityConfig"
        :tab-metadata="currentTab"
        :date-filter="dateFilter"
        :entity-id="entityId"
      />
    </div>
  </section>
</template>

<script>
import GenericTabsComponent from '@/components/basic/genericTabsComponent.vue';
export default {
  name: 'TabsComponent',
  components: {
    GenericTabsComponent
  },
  props: {
    entityId: {
      default: null,
      type: String
    },
    dateFilter: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    tabsMetadata: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      currentTab: null,
      key: 0
    };
  },
  computed: {
    tabData() {
      return this.tabsMetadata?.data?.tabs?.page_names || [];
    },
    tabsList() {
      return this.tabData.map(({ name }) => name);
    }
  },
  watch: {
    tabData: {
      handler(newVal) {
        this.currentTab = newVal[0];
      },
      immediate: true
    }
  },
  methods: {
    fetchComponent(currentTab) {
      if (
        !currentTab?.component ||
        !this?.entityConfig?.tabs[currentTab?.component]
      )
        return null;
      return this?.entityConfig?.tabs?.[currentTab?.component]?.component;
    },
    handleTabsClick(selectedTab) {
      this.key++;
      this.currentTab = this.tabData.find((tab) => tab.name === selectedTab);
    }
  }
};
</script>

<style lang="css">
.tabs-container .split-tabs {
  font-size: 1.1rem;
  margin: 2.4rem;
  margin-top: 0;
}
.tabs-container .split-tabs .u-border-radius-xl {
  border-radius: 24px;
}
.tabs-container .selected-component {
  border-top: none;
  box-shadow: none;
}
.tabs-container .custom-action-panel .action-header {
  padding-top: 0;
}
.tabs-container .bidChangePanel .title,
.tabs-container .freqCapChangePanel .title {
  padding-top: 0;
}
</style>
